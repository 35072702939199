import { useState, useCallback, createRef, useEffect } from 'react';
import Dropzone from "react-dropzone";
import { StyledPreview, StyledPreviewImage } from "./Preview.styled"
import { notify } from 'react-notify-toast';
import { useDebounce } from "../hooks/useDebounce"
import { toBlob } from "html-to-image";
import fileDownload from 'js-file-download'
import { isSafari, isIOS, isMobileSafari, isMacOs } from "react-device-detect";

import { StyledSlider } from "../shared/Slider";
import { DEFAULT_INVITE_TEXT, IMAGE_DOWNLOAD_FILE, IMAGE_DOWNLOAD_TITLE } from '../constants';


const Preview = ({ selectedInvite }) => {
    const [customText, setCustomText] = useState(DEFAULT_INVITE_TEXT);
    const [previewImage, setPreviewImage] = useState(null);
    const [inviteFile, setInviteFile] = useState(null);
    const inviteRef = createRef(null)
    const [transformScale, setTransformScale] = useState(1.0);
    const debouncedCustomText = useDebounce(customText, 1000);
    const debouncedTransformScale = useDebounce(transformScale, 1000);

    const downloadInvite = () => {
        if (previewImage && debouncedCustomText.trim() !== "") {
            notify.show("Downloading invite", "custom", 3000, { background: '#11819C', text: "#FFFFFF" });
            fileDownload(inviteFile, IMAGE_DOWNLOAD_FILE);
        } else {
            notify.show("Please, upload image and text", "error");
        }
    }

    const handleShare = async () => {
        if (inviteFile && navigator.share) {
            const data = {
                files: [
                    new File([inviteFile], IMAGE_DOWNLOAD_FILE, {
                        type: inviteFile.type
                    })
                ],
                title: IMAGE_DOWNLOAD_TITLE,
                text: debouncedCustomText
            };
            try {
                await navigator.share(data);
            } catch (err) {
                console.error(err);
            }
        }
    };

    const makeImage = useCallback(async () => {
        let newFile;
        /**
         * On iOS, Safari devices html is converted to png without the
         * images included in the html block. It shows white background 
         * replaced instead of the images. 
         */
        if (isSafari || isIOS || isMacOs || isMobileSafari) {
            await toBlob(inviteRef.current);
            await toBlob(inviteRef.current);
            newFile = await toBlob(inviteRef.current); // execute the function several times
        } else {
            newFile = await toBlob(inviteRef.current);
        }
        setInviteFile(newFile);
        notify.show("Image is ready for download and share", "custom", 3000, {
            background: "#11819C",
            text: "#FFFFFF"
        });
    }, [inviteRef]);

    useEffect(() => {
        if (previewImage && debouncedCustomText.trim() !== "") {
            notify.show("Generating image", "custom", 3000, { background: '#11819C', text: "#FFFFFF" });
            makeImage()
        }
    }, [debouncedCustomText, previewImage, selectedInvite, debouncedTransformScale])


    const handleDrop = useCallback((acceptedFiles) => {
        acceptedFiles.map((file) => {
            const reader = new FileReader();
            reader.onload = function (e) {
                setPreviewImage(e.target.result)
            };
            reader.readAsDataURL(file);
            return file;
        });
    }, []);

    const handleFileReject = () => {
        notify.show("Invalid format. Please, upload image", "error", 2000);
    }

    const handleTransformChange = (e) => {
        const { value } = e.target
        if (value === 0) {
            setTransformScale(1)
        } else {
            const scale = 1 + (value * 0.1)
            setTransformScale(scale);
        }
    }

    const handleCustomText = (e) => setCustomText(e.target.value)
    return (
        <StyledPreview>
            <div className="preview">
                <div className="preview-title">Preview</div>
                {selectedInvite && (
                    <>
                        <StyledPreviewImage image={selectedInvite.src} ref={inviteRef}>
                            {previewImage && (
                                <div className="preview-image-container">
                                    <img className="preview-image" style={{ transform: `scale(${transformScale})` }} src={previewImage} alt="Preview" />
                                </div>
                            )}
                            <div className="preview-text">
                                {customText}
                            </div>
                        </StyledPreviewImage>
                    </>
                )}
            </div>
            <div className="customize">
                <textarea value={customText} maxLength={110} onChange={handleCustomText} placeholder="Insert Custom Text Here!" className="custom-text" />
                <div className="divider" />
                <div className="upload-container">
                    <Dropzone
                        onDrop={handleDrop}
                        accept={{ 'image/*': [] }}
                        onDropRejected={handleFileReject}
                        minSize={1024}
                        maxSize={9216000}
                    >
                        {({
                            getRootProps,
                            getInputProps,
                            isDragActive,
                            isDragAccept,
                            isDragReject
                        }) => {
                            const additionalClass = isDragAccept
                                ? "accept"
                                : isDragReject
                                    ? "reject"
                                    : "";

                            return (
                                <div
                                    {...getRootProps({
                                        className: `dropzone ${additionalClass}`
                                    })}
                                >
                                    <input {...getInputProps()} />
                                    <span>{isDragActive ? "📂" : "📁"}</span>
                                    <p>Drag'n'drop image, or click to select picture</p>
                                </div>
                            );
                        }}
                    </Dropzone>
                </div>
                <div className="form-control">
                    <div className="form-label">
                        Zoom image
                    </div>
                    <div className="form-value">
                        <StyledSlider defaultValue={0} disabled={!previewImage} type="range" min="0" max="100" onChange={handleTransformChange} />
                    </div>
                </div>
                <div className="form-action">
                    <button className='download-button' disabled={!inviteFile} onClick={downloadInvite}>
                        Download Invite
                    </button>
                    {
                        !navigator.share && (
                            <button className='share-button' disabled={!inviteFile} onClick={handleShare}>Share</button>
                        )
                    }
                </div>
            </div>
        </StyledPreview>
    )
}

export default Preview;