import styled from "styled-components";
import logo from "../assets/images/soar_logo.png";
import { REGISTER_LINK } from "./constants";

const StyledHeader = styled.header`
  height: 100px;
  background: ${({ theme }) => theme.colors.white};
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: 0 170px;
  font-family: ${({ theme }) => theme.fonts.roboto}, sans-serif;
  @media (max-width: 760px) {
    padding: 0;
    display: grid;
    place-items: center;
    grid-template-columns: 1fr;
  }
  .image {
    height: 65px;
  }
  .navbar {
    justify-self: end;
    .link {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.primary};
      margin-right: 20px;
      background: transparent;
      border: none;
      cursor: pointer;
      font-size: ${({ theme }) => theme.fontSizes.normal};
    }
    .link:hover {
      border-bottom: 1px solid ${({ theme }) => theme.colors.secondary};
    }
    @media (max-width: 760px) {
      display: none;
    }
  }
`;

const Header = () => (
  <StyledHeader>
    <img src={logo} alt="SOAR Logo" className="image" />
    <nav className="navbar">
      <button className="link">Home</button>
      <a href={REGISTER_LINK} className="link" target="_blank" rel="noreferrer">
        Register
      </a>
    </nav>
  </StyledHeader>
);

export default Header;
