import { createGlobalStyle } from 'styled-components'
import Theme from "./Theme";
import Header from "./components/Header";
import Banner from "./components/Banner";
import Invite from "./components/Invite";
import About from "./components/About";
import Footer from "./components/Footer";
import Notifications from 'react-notify-toast';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background-color: #fff;
    font: 300 16px/22px "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
`;

function App() {
  return (
    <>
      <GlobalStyle />
      <Theme>
        <Notifications />
        <Header />
        <Banner />
        <Invite />
        <About />
        <Footer />
      </Theme>
    </>
  );
}

export default App;
