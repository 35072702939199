import React from "react";
import { ThemeProvider } from "styled-components";

const theme = {
  colors: {
    primary: "#01356F",
    secondary: "#007EC1",
    white: "#FFFFFF",
    red: "#C01327",
    light: "#F5F5F5",
    dividerGrey: "#A4A4A4",
    darkGunMetal: "#22242C",
    lotion: "#FAFAFA",
    taupeGray: "#888888",
    charlestonGreen: "#2D2D2D",
    black: "#171621",
    richBlack: "#0E3A46"
  },
  fonts: {
    inter: "Inter",
    roboto: "Roboto"
  },
  fontSizes: {
    tiny: "10px",
    small: "12px",
    normal: "16px",
    large: "20px",
    title: "39px"
  },
  fontWeights: {
    normal: 400,
    bold: 500,
    xbold: 700
  }
};

const Theme = ({ children }) => (
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;